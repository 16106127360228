import React from 'react';
import { Link } from 'react-router-dom';
import { DocumentIcon } from '@heroicons/react/24/outline';

export default function ProjectsList({ projects }) {
  if (!projects || projects.length === 0) {
    return (
      <div className="text-center py-6">
        <p className="text-gray-500 dark:text-gray-400">No projects found</p>
      </div>
    );
  }

  return (
    <ul role="list" className="divide-y divide-gray-100 dark:divide-gray-700">
      {projects.map((project) => (
        <li key={project.id} className="flex items-center justify-between gap-x-6 py-5">
          <div className="min-w-0">
            <div className="flex items-start gap-x-3">
              <p className="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-100">{project.name}</p>
              <p className={`rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset 
                ${project.status === 'completed' 
                  ? 'bg-green-50 dark:bg-green-900 text-green-700 dark:text-green-200 ring-green-600/20'
                  : project.status === 'in_progress'
                  ? 'bg-blue-50 dark:bg-blue-900 text-blue-700 dark:text-blue-200 ring-blue-600/20'
                  : 'bg-gray-50 dark:bg-gray-800 text-gray-600 dark:text-gray-300 ring-gray-500/10'
                }`}>
                {project.status === 'in_progress' ? 'In Progress' : 
                 project.status === 'completed' ? 'Completed' : 'Not Started'}
              </p>
            </div>
            <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500 dark:text-gray-400">
              <p className="whitespace-nowrap">
                Created on {new Date(project.createdAt).toLocaleDateString()}
              </p>
              <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                <circle cx={1} cy={1} r={1} />
              </svg>
              <p className="truncate">
                {project.documents?.length || 0} documents
              </p>
            </div>
          </div>
          <div className="flex flex-none items-center gap-x-4">
            <Link
              to={`/projects/${project.id}`}
              className="hidden rounded-md bg-white dark:bg-gray-700 px-2.5 py-1.5 text-sm font-semibold text-gray-900 dark:text-gray-100 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-600 hover:bg-gray-50 dark:hover:bg-gray-600 sm:block"
            >
              View project<span className="sr-only">, {project.name}</span>
            </Link>
          </div>
        </li>
      ))}
    </ul>
  );
}
