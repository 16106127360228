import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import {
  ArrowLeftIcon,
  BuildingOfficeIcon,
  DocumentIcon,
  EnvelopeIcon,
  MapPinIcon,
  PhoneIcon,
} from '@heroicons/react/24/outline';
import ProjectsList from '../projects/components/ProjectsList';
import NewProjectButton from '../projects/components/NewProjectButton';
import LoadingSpinner from '../../components/LoadingSpinner';
import ErrorAlert from '../../components/ErrorAlert';

const STATUS_STYLES = {
  active: 'bg-green-50 dark:bg-green-900 text-green-700 dark:text-green-200 ring-green-600/20',
  inactive: 'bg-red-50 dark:bg-red-900 text-red-700 dark:text-red-200 ring-red-600/20',
  prospective: 'bg-yellow-50 dark:bg-yellow-900 text-yellow-700 dark:text-yellow-200 ring-yellow-600/20',
};

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function VendorDetailsPage() {
  const { vendorId } = useParams();
  const navigate = useNavigate();
  const [vendor, setVendor] = useState(null);
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('overview');

  useEffect(() => {
    const loadVendorAndProjects = async () => {
      try {
        setLoading(true);
        setError(null);

        // Fetch vendor details
        const response = await fetch(`/api/vendors/${vendorId}`);
        if (!response.ok) {
          throw new Error('Failed to fetch vendor details');
        }
        
        const vendorData = await response.json();
        if (!vendorData) {
          throw new Error('Vendor not found');
        }
        
        setVendor(vendorData);
        
        // Fetch vendor's projects
        const projectsResponse = await fetch(`/api/vendors/${vendorId}/projects`);
        if (!projectsResponse.ok) {
          throw new Error('Failed to fetch vendor projects');
        }
        
        const projectsData = await projectsResponse.json();
        setProjects(projectsData || []);
      } catch (err) {
        console.error('Error loading vendor details:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    loadVendorAndProjects();
  }, [vendorId]);

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <ErrorAlert message={error} />;
  }

  if (!vendor) {
    return <ErrorAlert message="Vendor not found" />;
  }

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      {/* Header */}
      <div className="md:flex md:items-center md:justify-between md:space-x-4 mb-6">
        <div className="flex items-center space-x-4">
          <button
            onClick={() => navigate('/vendors')}
            className="flex items-center text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-gray-100"
          >
            <ArrowLeftIcon className="h-5 w-5 mr-2" />
            Back to Vendors
          </button>
          <div className="h-12 w-12 rounded-lg bg-indigo-100 dark:bg-indigo-900 flex items-center justify-center">
            <span className="text-xl font-medium text-indigo-600 dark:text-indigo-300">
              {vendor.name.charAt(0)}
            </span>
          </div>
          <div>
            <h1 className="text-2xl font-bold text-gray-900 dark:text-gray-100">{vendor.name}</h1>
            <p className="text-gray-500 dark:text-gray-400">{vendor.email}</p>
          </div>
        </div>
      </div>

      {/* Tabs */}
      <div className="border-b border-gray-200 dark:border-gray-700">
        <nav className="-mb-px flex space-x-8">
          <button
            onClick={() => setActiveTab('overview')}
            className={classNames(
              activeTab === 'overview'
                ? 'border-indigo-500 text-indigo-600 dark:text-indigo-400'
                : 'border-transparent text-gray-500 dark:text-gray-400 hover:border-gray-300 dark:hover:border-gray-600 hover:text-gray-700 dark:hover:text-gray-300',
              'whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm'
            )}
          >
            Overview
          </button>
          <button
            onClick={() => setActiveTab('projects')}
            className={classNames(
              activeTab === 'projects'
                ? 'border-indigo-500 text-indigo-600 dark:text-indigo-400'
                : 'border-transparent text-gray-500 dark:text-gray-400 hover:border-gray-300 dark:hover:border-gray-600 hover:text-gray-700 dark:hover:text-gray-300',
              'whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm'
            )}
          >
            Projects
          </button>
          <button
            onClick={() => setActiveTab('documents')}
            className={classNames(
              activeTab === 'documents'
                ? 'border-indigo-500 text-indigo-600 dark:text-indigo-400'
                : 'border-transparent text-gray-500 dark:text-gray-400 hover:border-gray-300 dark:hover:border-gray-600 hover:text-gray-700 dark:hover:text-gray-300',
              'whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm'
            )}
          >
            Documents
          </button>
        </nav>
      </div>

      {/* Content */}
      <div className="mt-6">
        {activeTab === 'overview' && (
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
            <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6">
              <h3 className="text-lg font-medium text-gray-900 dark:text-gray-100 mb-4">Contact Information</h3>
              <div className="space-y-4">
                <div className="flex items-center space-x-3 text-gray-500 dark:text-gray-400">
                  <EnvelopeIcon className="h-5 w-5" />
                  <span>{vendor.email}</span>
                </div>
                <div className="flex items-center space-x-3 text-gray-500 dark:text-gray-400">
                  <PhoneIcon className="h-5 w-5" />
                  <span>{vendor.phone}</span>
                </div>
                <div className="flex items-center space-x-3 text-gray-500 dark:text-gray-400">
                  <MapPinIcon className="h-5 w-5" />
                  <span>{vendor.address || 'No address provided'}</span>
                </div>
              </div>
            </div>
            <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6">
              <h3 className="text-lg font-medium text-gray-900 dark:text-gray-100 mb-4">Status</h3>
              <span
                className={classNames(
                  STATUS_STYLES[vendor.status],
                  'inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset'
                )}
              >
                {vendor.status}
              </span>
            </div>
          </div>
        )}

        {activeTab === 'projects' && (
          <div>
            <div className="mb-4 flex justify-between items-center">
              <h3 className="text-lg font-medium text-gray-900 dark:text-gray-100">Projects</h3>
              <NewProjectButton vendorId={vendorId} />
            </div>
            <ProjectsList projects={projects} />
          </div>
        )}

        {activeTab === 'documents' && (
          <div>
            <div className="mb-4 flex justify-between items-center">
              <h3 className="text-lg font-medium text-gray-900 dark:text-gray-100">Documents</h3>
              <button className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500">
                Upload Document
              </button>
            </div>
            {vendor.documents && vendor.documents.length > 0 ? (
              <ul role="list" className="divide-y divide-gray-100 dark:divide-gray-700">
                {vendor.documents.map((doc) => (
                  <li key={doc.id} className="flex items-center justify-between gap-x-6 py-5">
                    <div className="flex min-w-0 gap-x-4">
                      <DocumentIcon className="h-12 w-12 flex-none text-gray-400 dark:text-gray-500" />
                      <div className="min-w-0 flex-auto">
                        <p className="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-100">{doc.name}</p>
                        <p className="mt-1 truncate text-xs leading-5 text-gray-500 dark:text-gray-400">
                          Uploaded on {new Date(doc.createdAt).toLocaleDateString()}
                        </p>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <p className="text-gray-500 dark:text-gray-400">No documents found.</p>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
