import React, { useState, useEffect } from 'react';
import { PlusIcon, FunnelIcon } from '@heroicons/react/24/outline';
import VendorTable from './components/VendorTable';
import AddVendorModal from './components/AddVendorModal';
import VendorFilters from './components/VendorFilters';

export default function VendorsPage() {
  const [vendors, setVendors] = useState([]);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [activeFilters, setActiveFilters] = useState({
    status: ['active', 'prospective'],
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchVendors = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await fetch('/api/vendors');
        if (!response.ok) {
          const errorData = await response.json();
          if (response.status === 429) {
            throw new Error(`${errorData.error} Please wait a minute before trying again.`);
          }
          throw new Error(errorData.error || 'Failed to fetch vendors');
        }
        const data = await response.json();
        setVendors(data);
      } catch (err) {
        console.error('Error fetching vendors:', err);
        setError(err.message || 'Failed to load vendors. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchVendors();
  }, []);

  const handleAddVendor = async (vendorData) => {
    try {
      const response = await fetch('/api/vendors', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(vendorData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to add vendor');
      }

      const newVendor = await response.json();
      setVendors(prevVendors => [...prevVendors, newVendor]);
      setIsAddModalOpen(false);
    } catch (error) {
      console.error('Error adding vendor:', error);
      throw error; // Re-throw to be handled by the modal
    }
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900 dark:text-gray-100">
            Vendors
          </h1>
          <p className="mt-2 text-sm text-gray-700 dark:text-gray-300">
            A list of all vendors including their name, status, and key metrics.
          </p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none space-x-2">
          <button
            type="button"
            onClick={() => setShowFilters(!showFilters)}
            className="inline-flex items-center rounded-md bg-white dark:bg-gray-700 px-3 py-2 text-sm font-semibold text-gray-900 dark:text-gray-100 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-600 hover:bg-gray-50 dark:hover:bg-gray-600"
          >
            <FunnelIcon className="h-5 w-5 mr-2" />
            Filters
          </button>
          <button
            type="button"
            onClick={() => setIsAddModalOpen(true)}
            className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            <PlusIcon className="h-5 w-5 inline-block mr-2" />
            Add Vendor
          </button>
        </div>
      </div>

      {showFilters && (
        <VendorFilters
          filters={activeFilters}
          onFilterChange={setActiveFilters}
        />
      )}

      {error ? (
        <div className="mt-8 text-center text-red-600">{error}</div>
      ) : (
        <VendorTable
          vendors={vendors}
          loading={loading}
        />
      )}

      <AddVendorModal
        open={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
        onSubmit={handleAddVendor}
      />
    </div>
  );
}
