import React from 'react';
import { XCircleIcon } from '@heroicons/react/24/solid';

export default function ErrorAlert({ message }) {
  if (!message) return null;

  return (
    <div className="rounded-md bg-red-50 dark:bg-red-900/50 p-4 my-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <XCircleIcon className="h-5 w-5 text-red-400 dark:text-red-300" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-red-800 dark:text-red-200">Error</h3>
          <div className="mt-2 text-sm text-red-700 dark:text-red-300">
            {message}
          </div>
        </div>
      </div>
    </div>
  );
}
