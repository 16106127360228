import React from 'react';
import { classNames } from '../../../utils/classNames';

const STATUS_OPTIONS = [
  { label: 'Active', value: 'active' },
  { label: 'Prospective', value: 'prospective' },
  { label: 'Non-Active', value: 'non-active' },
];

export default function VendorFilters({ filters, onFilterChange }) {
  const handleStatusChange = (status) => {
    const newStatuses = filters.status.includes(status)
      ? filters.status.filter((s) => s !== status)
      : [...filters.status, status];

    onFilterChange({
      ...filters,
      status: newStatuses,
    });
  };

  return (
    <div className="mt-4 sm:mt-6 bg-white dark:bg-gray-800 shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:p-6">
        <div className="space-y-4">
          <div>
            <h3 className="text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">Status</h3>
            <div className="mt-2 flex flex-wrap gap-2">
              {STATUS_OPTIONS.map((option) => (
                <button
                  key={option.value}
                  onClick={() => handleStatusChange(option.value)}
                  type="button"
                  className={classNames(
                    'inline-flex items-center rounded-full px-3 py-1.5 text-sm font-medium',
                    filters.status.includes(option.value)
                      ? 'bg-indigo-100 dark:bg-indigo-900 text-indigo-700 dark:text-indigo-200'
                      : 'bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-300'
                  )}
                >
                  {option.label}
                </button>
              ))}
            </div>
          </div>

          <div className="flex justify-end border-t border-gray-200 dark:border-gray-700 pt-4">
            <button
              type="button"
              onClick={() => onFilterChange({ status: [] })}
              className="text-sm font-medium text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-gray-200"
            >
              Clear all filters
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
