import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { XMarkIcon } from '@heroicons/react/24/outline';

export default function SignDocumentModal({ isOpen, document, onClose, onSubmit }) {
  const [signature, setSignature] = useState('');
  const [signatureType, setSignatureType] = useState('typed'); // typed or drawn
  const [signedBy, setSignedBy] = useState('');
  const [title, setTitle] = useState('');
  const [date, setDate] = useState(new Date().toISOString().split('T')[0]);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({
      signature,
      signatureType,
      signedBy,
      title,
      date,
      documentId: document.id,
    });
  };

  if (!isOpen) return null;

  return (
    <div className="modal modal-open">
      <div className="modal-box max-w-2xl">
        <div className="flex items-center justify-between">
          <h3 className="text-lg font-bold">Sign Document</h3>
          <button
            onClick={onClose}
            className="btn btn-ghost btn-sm"
          >
            <XMarkIcon className="h-5 w-5" />
          </button>
        </div>

        <div className="divider" />

        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="grid gap-4 md:grid-cols-2">
            <div className="form-control">
              <label className="label">
                <span className="label-text">Full Name</span>
              </label>
              <input
                type="text"
                className="input input-bordered"
                value={signedBy}
                onChange={(e) => setSignedBy(e.target.value)}
                required
              />
            </div>

            <div className="form-control">
              <label className="label">
                <span className="label-text">Title/Position</span>
              </label>
              <input
                type="text"
                className="input input-bordered"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
              />
            </div>
          </div>

          <div className="form-control">
            <label className="label">
              <span className="label-text">Signature Type</span>
            </label>
            <select
              className="select select-bordered w-full"
              value={signatureType}
              onChange={(e) => setSignatureType(e.target.value)}
            >
              <option value="typed">Typed Signature</option>
              <option value="drawn">Draw Signature</option>
            </select>
          </div>

          {signatureType === 'typed' ? (
            <div className="form-control">
              <label className="label">
                <span className="label-text">Type your signature</span>
              </label>
              <input
                type="text"
                className="input input-bordered font-signature text-xl"
                value={signature}
                onChange={(e) => setSignature(e.target.value)}
                required
                placeholder="Type your full name"
              />
            </div>
          ) : (
            <div className="form-control">
              <label className="label">
                <span className="label-text">Draw your signature</span>
              </label>
              <div className="h-40 rounded-lg border-2 border-dashed border-base-content/20 bg-base-200">
                {/* TODO: Implement signature pad */}
                <div className="flex h-full items-center justify-center text-base-content/50">
                  Signature pad coming soon
                </div>
              </div>
            </div>
          )}

          <div className="form-control">
            <label className="label">
              <span className="label-text">Date</span>
            </label>
            <input
              type="date"
              className="input input-bordered"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              required
            />
          </div>

          <div className="mt-6 flex justify-end gap-2">
            <button
              type="button"
              className="btn btn-ghost"
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn btn-primary"
            >
              Sign Document
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

SignDocumentModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  document: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
