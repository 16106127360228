import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from './Sidebar';
import clsx from 'clsx';

export default function MainLayout() {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  // Close sidebar by default on mobile
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1024) {
        setSidebarOpen(false);
      } else {
        setSidebarOpen(true);
      }
    };

    // Set initial state
    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="min-h-screen bg-app-black text-gray-100">
      <div className="flex">
        <Sidebar isOpen={sidebarOpen} setIsOpen={setSidebarOpen} />
        <div 
          className={clsx(
            'flex-1 transition-all duration-200',
            sidebarOpen ? 'ml-64' : 'ml-20',
            'p-8'
          )}
        >
          <Outlet />
        </div>
      </div>
    </div>
  );
}
