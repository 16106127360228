import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import { ThemeProvider } from './contexts/ThemeContext';
import ProtectedRoute from './components/auth/ProtectedRoute';
import MainLayout from './components/layout/MainLayout';
import LoginPage from './features/auth/LoginPage';
import Dashboard from './features/dashboard/DashboardPage';
import VendorsPage from './features/vendors/VendorsPage';
import VendorDetailsPage from './features/vendors/VendorDetailsPage';
import DocumentsPage from './features/documents/DocumentsPage';
import DocumentDetailsPage from './features/documents/DocumentDetailsPage';
import NewProjectPage from './features/projects/NewProjectPage';
import ProjectDetailsPage from './features/projects/ProjectDetailsPage';
import ProjectsPage from './features/projects/ProjectsPage';
import UsersPage from './features/users/UsersPage';

// Configure future flags for React Router
const router = {
  future: {
    v7_startTransition: true,
    v7_relativeSplatPath: true
  }
};

function App() {
  return (
    <ThemeProvider>
      <AuthProvider>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <MainLayout />
              </ProtectedRoute>
            }
          >
            <Route index element={<Dashboard />} />
            <Route path="vendors" element={<VendorsPage />} />
            <Route path="vendors/:vendorId" element={<VendorDetailsPage />} />
            <Route path="vendors/:vendorId/projects/new" element={<NewProjectPage />} />
            <Route path="vendors/:vendorId/projects/:projectId" element={<ProjectDetailsPage />} />
            <Route path="projects" element={<ProjectsPage />} />
            <Route path="projects/:projectId" element={<ProjectDetailsPage />} />
            <Route path="documents" element={<DocumentsPage />} />
            <Route path="documents/:documentId" element={<DocumentDetailsPage />} />
            <Route path="users" element={<UsersPage />} />
          </Route>
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
