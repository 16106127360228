import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTheme } from '../../hooks/useTheme';
import { useAuth } from '../../contexts/AuthContext';
import DarkModeToggle from '../DarkModeToggle';
import {
  HomeIcon,
  BriefcaseIcon,
  ClipboardDocumentIcon,
  UserGroupIcon,
  Bars3Icon,
  XMarkIcon
} from '@heroicons/react/24/outline';
import clsx from 'clsx';

const navigation = [
  { name: 'Dashboard', href: '/', icon: HomeIcon },
  { name: 'Vendors', href: '/vendors', icon: BriefcaseIcon },
  { name: 'Projects', href: '/projects', icon: ClipboardDocumentIcon },
  { name: 'Users', href: '/users', icon: UserGroupIcon },
];

export default function Sidebar({ isOpen, setIsOpen }) {
  const location = useLocation();
  const { isDarkMode, toggleDarkMode } = useTheme();
  const { currentUser, logout } = useAuth();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const isActive = (href) => {
    return location.pathname === href;
  };

  const handleLogout = async () => {
    try {
      await logout();
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  // Format user's name to show first name and last initial
  const formatDisplayName = (user) => {
    if (!user) return '';
    
    if (user.displayName) {
      const names = user.displayName.split(' ');
      if (names.length > 1) {
        return `${names[0]} ${names[names.length - 1][0]}`;
      }
      return names[0];
    }
    
    const emailName = user.email.split('@')[0];
    const names = emailName.split(/[._-]/);
    if (names.length > 1) {
      return `${names[0]} ${names[names.length - 1][0]}`;
    }
    return emailName;
  };

  // Get user's initials for avatar fallback
  const getInitials = (user) => {
    if (!user) return '';
    
    if (user.displayName) {
      return user.displayName
        .split(' ')
        .map(name => name[0])
        .join('')
        .toUpperCase()
        .slice(0, 2);
    }
    
    return user.email[0].toUpperCase();
  };

  return (
    <div className={clsx(
      'fixed top-0 left-0 h-screen flex flex-col',
      'bg-app-black border-r border-gray-800',
      isOpen ? 'w-64' : 'w-20'
    )}>
      {/* Logo section */}
      <div className="flex-shrink-0 flex justify-center items-center h-24 px-4 border-b border-gray-800">
        <img 
          src={'/doxument_darkbg.png'}
          alt="Doxument" 
          className="h-16 w-auto object-contain"
        />
      </div>

      {/* Navigation */}
      <nav className="flex-1 px-4 py-4 space-y-1 overflow-y-auto">
        {navigation.map((item) => {
          const active = isActive(item.href);
          return (
            <Link
              key={item.name}
              to={item.href}
              className={clsx(
                'flex items-center px-3 py-2 rounded-lg text-sm font-medium transition-all duration-150',
                active
                  ? 'bg-accent-teal/10 text-accent-teal shadow-glow-teal'
                  : 'text-gray-400 hover:bg-accent-blue/10 hover:text-accent-blue hover:shadow-glow-blue'
              )}
            >
              <item.icon
                className={clsx(
                  'flex-shrink-0 h-5 w-5 mr-3',
                  active
                    ? 'text-accent-teal'
                    : 'text-gray-500 group-hover:text-accent-blue'
                )}
              />
              <span className={isOpen ? 'block' : 'hidden'}>
                {item.name}
              </span>
            </Link>
          );
        })}
      </nav>

      {/* User section - fixed at bottom */}
      <div className="flex-shrink-0 border-t border-gray-800">
        <div className="p-4">
          <div className="flex items-center gap-3">
            {currentUser?.photoURL ? (
              <img
                src={currentUser.photoURL}
                alt={formatDisplayName(currentUser)}
                className="h-8 w-8 rounded-full object-cover ring-2 ring-accent-purple"
                referrerPolicy="no-referrer"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = '/default-avatar.svg';
                }}
              />
            ) : (
              <div className="h-8 w-8 rounded-full bg-accent-purple/10 flex items-center justify-center ring-2 ring-accent-purple">
                <span className="text-sm font-medium text-accent-purple">
                  {getInitials(currentUser)}
                </span>
              </div>
            )}
            {isOpen && (
              <div className="flex-1 min-w-0">
                <div className="text-sm font-medium text-gray-300 truncate">
                  {formatDisplayName(currentUser)}
                </div>
              </div>
            )}
            <div className="flex items-center gap-2">
              <DarkModeToggle isDarkMode={isDarkMode} onToggle={toggleDarkMode} />
              <button
                onClick={handleLogout}
                className="p-2 text-gray-400 hover:text-accent-teal hover:bg-accent-teal/10 rounded-lg transition-all duration-150 hover:shadow-glow-teal"
                title="Logout"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-5 h-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
