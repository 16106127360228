import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App.jsx';
import './index.css';
import * as Sentry from "@sentry/react";

// Initialize Sentry
if (import.meta.env.VITE_SENTRY_DSN) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.MODE,
    release: import.meta.env.VITE_RELEASE_VERSION || '1.0.0',
    integrations: [
      new Sentry.BrowserTracing({
        // Set sampling rate for performance monitoring
        tracingOrigins: ["localhost", "your-production-domain.com"],
        tracePropagationTargets: [
          "localhost",
          /^https:\/\/doxument\.com/,
          /^https:\/\/doxument\.railway\.app/,
          /^https:\/\/doxument-production\.up\.railway\.app/,
        ],
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(),
      }),
      new Sentry.Replay({
        maskAllText: true,
        blockAllMedia: true,
      }),
      new Sentry.Integrations.GlobalHandlers({
        onerror: true,
        onunhandledrejection: true,
      }),
      new Sentry.Integrations.Breadcrumbs({
        console: true,
        dom: true,
        fetch: true,
        history: true,
        xhr: true,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: import.meta.env.MODE === 'production' ? 0.2 : 1.0,
    // Session Replay
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    attachStacktrace: true,
    normalizeDepth: 10,
    beforeSend(event, hint) {
      if (import.meta.env.MODE !== 'production') {
        return null;
      }

      // Add browser information
      event.extra = {
        ...event.extra,
        userAgent: navigator.userAgent,
        viewport: {
          width: window.innerWidth,
          height: window.innerHeight,
        },
      };

      // Add error cause if available
      const error = hint?.originalException;
      if (error?.cause) {
        event.extra.errorCause = error.cause;
      }

      // Log the error to console in development
      if (import.meta.env.MODE === 'development') {
        console.error('Sentry error:', error);
      }

      return event;
    },
    beforeBreadcrumb(breadcrumb, hint) {
      if (
        breadcrumb.category === 'ui.input' && 
        hint?.input?.type === 'password'
      ) {
        return null;
      }
      return breadcrumb;
    },
    debug: import.meta.env.MODE !== 'production',
  });
}

// Create error boundary wrapper with custom fallback
const SentryErrorBoundary = Sentry.withErrorBoundary(App, {
  fallback: ({ error, componentStack, resetError }) => (
    <div className="min-h-screen bg-gray-50 flex flex-col items-center justify-center p-4">
      <div className="max-w-md w-full space-y-8 bg-white p-6 rounded-lg shadow-md">
        <div className="text-center">
          <h2 className="text-2xl font-bold text-gray-900 mb-2">
            Oops! Something went wrong
          </h2>
          <p className="text-gray-600 mb-4">
            {error.message || "An unexpected error occurred"}
          </p>
          {import.meta.env.MODE === 'development' && (
            <pre className="text-left text-sm bg-gray-100 p-4 rounded overflow-auto max-h-40 mb-4">
              {error.stack}
              {componentStack}
            </pre>
          )}
          <button
            onClick={resetError}
            className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition-colors"
          >
            Try again
          </button>
        </div>
      </div>
    </div>
  ),
  showDialog: true,
  dialogOptions: {
    title: 'An Error Occurred',
    subtitle: 'Our team has been notified.',
    subtitle2: 'If you\'d like to help, tell us what happened below.',
  }
});

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <BrowserRouter>
      <SentryErrorBoundary />
    </BrowserRouter>
  </React.StrictMode>
);
