import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ChevronRightIcon,
  DocumentIcon,
  FolderIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/24/outline';
import { classNames } from '../../../utils/classNames';

const STATUS_STYLES = {
  active: 'bg-green-50 dark:bg-green-900 text-green-700 dark:text-green-200 ring-green-600/20',
  prospective: 'bg-blue-50 dark:bg-blue-900 text-blue-700 dark:text-blue-200 ring-blue-600/20',
  'non-active': 'bg-gray-50 dark:bg-gray-800 text-gray-600 dark:text-gray-300 ring-gray-500/10',
};

export default function VendorTable({ vendors, loading }) {
  const navigate = useNavigate();

  if (loading) {
    return (
      <div className="min-w-full divide-y divide-gray-300 dark:divide-gray-700">
        <div className="bg-white dark:bg-gray-800 px-4 py-5 sm:px-6">
          <div className="animate-pulse flex space-x-4">
            <div className="flex-1 space-y-6 py-1">
              {[...Array(5)].map((_, i) => (
                <div key={i} className="space-y-3">
                  <div className="h-2 bg-slate-200 dark:bg-slate-700 rounded col-span-2"></div>
                  <div className="h-2 bg-slate-200 dark:bg-slate-700 rounded"></div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (!vendors?.length) {
    return (
      <div className="text-center py-12">
        <DocumentIcon className="mx-auto h-12 w-12 text-gray-400 dark:text-gray-500" />
        <h3 className="mt-2 text-sm font-semibold text-gray-900 dark:text-gray-100">No vendors</h3>
        <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
          Get started by adding a new vendor.
        </p>
      </div>
    );
  }

  return (
    <div className="min-w-full divide-y divide-gray-300 dark:divide-gray-700">
      <div className="bg-gray-50 dark:bg-gray-800 px-4 py-3 sm:px-6">
        <div className="grid grid-cols-12 text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
          <div className="col-span-4">Name</div>
          <div className="col-span-2">Status</div>
          <div className="col-span-2">Projects</div>
          <div className="col-span-2">Documents</div>
          <div className="col-span-2">Expiring</div>
        </div>
      </div>
      <div className="divide-y divide-gray-200 dark:divide-gray-700 bg-white dark:bg-gray-800">
        {vendors.map((vendor) => (
          <div
            key={vendor.id}
            onClick={() => navigate(`/vendors/${vendor.id}`)}
            className="hover:bg-gray-50 dark:hover:bg-gray-700 cursor-pointer"
          >
            <div className="px-4 py-4 sm:px-6">
              <div className="grid grid-cols-12 items-center">
                <div className="col-span-4">
                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                      <div className="h-10 w-10 rounded-lg bg-gray-200 dark:bg-gray-700 flex items-center justify-center">
                        <span className="text-lg font-medium text-gray-600 dark:text-gray-300">
                          {vendor.name.charAt(0)}
                        </span>
                      </div>
                    </div>
                    <div className="ml-4">
                      <div className="font-medium text-gray-900 dark:text-gray-100">
                        {vendor.name}
                      </div>
                      <div className="text-sm text-gray-500 dark:text-gray-400">
                        {vendor.email || 'No email provided'}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-span-2">
                  <span
                    className={classNames(
                      STATUS_STYLES[vendor.status],
                      'inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset'
                    )}
                  >
                    {vendor.status}
                  </span>
                </div>
                <div className="col-span-2">
                  <div className="flex items-center">
                    <FolderIcon className="h-5 w-5 text-gray-400 dark:text-gray-500 mr-2" />
                    <span className="text-gray-900 dark:text-gray-100">{vendor.projectCount}</span>
                  </div>
                </div>
                <div className="col-span-2">
                  <div className="flex items-center">
                    <DocumentIcon className="h-5 w-5 text-gray-400 dark:text-gray-500 mr-2" />
                    <span className="text-gray-900 dark:text-gray-100">{vendor.documentCount}</span>
                  </div>
                </div>
                <div className="col-span-2">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <ExclamationTriangleIcon
                        className={classNames(
                          vendor.expiringContracts > 0
                            ? 'text-yellow-500'
                            : 'text-gray-400 dark:text-gray-500',
                          'h-5 w-5 mr-2'
                        )}
                      />
                      <span className="text-gray-900 dark:text-gray-100">
                        {vendor.expiringContracts}
                      </span>
                    </div>
                    <ChevronRightIcon className="h-5 w-5 text-gray-400 dark:text-gray-500" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
