import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import {
  BuildingOfficeIcon,
  MapPinIcon,
  DocumentIcon,
  CalendarIcon,
  UserGroupIcon,
  TagIcon,
  ArrowLeftIcon,
} from '@heroicons/react/24/outline';

const STATUS_STYLES = {
  active: 'badge-success',
  pending: 'badge-warning',
  completed: 'badge-info',
  cancelled: 'badge-error',
  in_progress: 'badge-success',
};

const LoadingSpinner = () => (
  <div className="animate-pulse">
    <div className="h-8 bg-gray-700 rounded w-1/4 mb-4"></div>
    <div className="space-y-4">
      {[...Array(3)].map((_, i) => (
        <div key={i} className="h-24 bg-gray-700 rounded"></div>
      ))}
    </div>
  </div>
);

const ErrorAlert = ({ message }) => (
  <div className="text-center py-12">
    <h2 className="text-xl font-semibold text-gray-300 mb-2">Error Loading Project</h2>
    <p className="text-gray-400 mb-4">{message}</p>
    <Link
      to={useParams().vendorId ? `/vendors/${useParams().vendorId}` : "/projects"}
      className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-accent-teal hover:bg-accent-teal/80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-accent-teal transition-all duration-150 hover:shadow-glow-teal"
    >
      {useParams().vendorId ? 'Back to Vendor' : 'Back to Projects'}
    </Link>
  </div>
);

export default function ProjectDetailsPage() {
  const { projectId, vendorId } = useParams();
  const navigate = useNavigate();
  const [project, setProject] = useState(null);
  const [vendor, setVendor] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('overview');

  useEffect(() => {
    const fetchProjectAndVendor = async () => {
      try {
        setLoading(true);
        setError(null);

        let projectData;
        // If we have a vendorId, fetch through the vendor's projects endpoint
        if (vendorId) {
          const projectsResponse = await fetch(`/api/vendors/${vendorId}/projects`);
          if (!projectsResponse.ok) {
            throw new Error('Failed to fetch vendor projects');
          }
          const projects = await projectsResponse.json();
          projectData = projects.find(p => p.id === projectId);
        } else {
          // Otherwise fetch directly from the projects endpoint
          const projectResponse = await fetch(`/api/projects/${projectId}`);
          if (!projectResponse.ok) {
            throw new Error('Failed to fetch project');
          }
          projectData = await projectResponse.json();
        }

        if (!projectData) {
          throw new Error('Project not found');
        }

        setProject(projectData);

        // If we have a vendorId (either from URL or project data), fetch vendor details
        const projectVendorId = vendorId || projectData.vendorId;
        if (projectVendorId) {
          const vendorResponse = await fetch(`/api/vendors/${projectVendorId}`);
          if (vendorResponse.ok) {
            const vendorData = await vendorResponse.json();
            setVendor(vendorData);
            // Update project with vendor information
            setProject(prev => ({
              ...prev,
              vendor: vendorData.name,
              vendorId: vendorData.id
            }));
          }
        }
      } catch (err) {
        console.error('Error fetching project details:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (projectId) {
      fetchProjectAndVendor();
    }
  }, [projectId, vendorId]);

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <ErrorAlert message={error} />;
  }

  if (!project) {
    return <ErrorAlert message="Project not found" />;
  }

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      {/* Header */}
      <div className="md:flex md:items-center md:justify-between md:space-x-4 mb-6">
        <div className="flex items-center space-x-4">
          <button
            onClick={() => navigate(vendorId ? `/vendors/${vendorId}` : '/projects')}
            className="flex items-center text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-gray-100"
          >
            <ArrowLeftIcon className="h-5 w-5 mr-2" />
            Back to {vendorId ? 'Vendor' : 'Projects'}
          </button>
          <div className="h-12 w-12 rounded-lg bg-indigo-100 dark:bg-indigo-900 flex items-center justify-center">
            <span className="text-xl font-medium text-indigo-600 dark:text-indigo-300">
              {project.name?.charAt(0) || 'P'}
            </span>
          </div>
        </div>
      </div>

      {/* Tabs */}
      <div className="tabs-boxed tabs">
        <button
          className={`tab ${activeTab === 'overview' ? 'tab-active' : ''}`}
          onClick={() => setActiveTab('overview')}
        >
          Overview
        </button>
        <button
          className={`tab ${activeTab === 'locations' ? 'tab-active' : ''}`}
          onClick={() => setActiveTab('locations')}
        >
          Locations
        </button>
        <button
          className={`tab ${activeTab === 'team' ? 'tab-active' : ''}`}
          onClick={() => setActiveTab('team')}
        >
          Team
        </button>
        <button
          className={`tab ${activeTab === 'documents' ? 'tab-active' : ''}`}
          onClick={() => setActiveTab('documents')}
        >
          Documents
        </button>
      </div>

      {/* Content */}
      {activeTab === 'overview' && (
        <div className="grid gap-6 lg:grid-cols-3">
          {/* Project Details */}
          <div className="card bg-base-200">
            <div className="card-body">
              <h2 className="card-title">Project Details</h2>
              <div className="space-y-4">
                <div className="flex items-start gap-2">
                  <CalendarIcon className="mt-1 h-5 w-5 flex-shrink-0" />
                  <div>
                    <p className="font-medium">Timeline</p>
                    <p className="text-base-content/70">
                      {project.startDate} - {project.endDate}
                    </p>
                  </div>
                </div>
                <div className="flex items-start gap-2">
                  <TagIcon className="mt-1 h-5 w-5 flex-shrink-0" />
                  <div>
                    <p className="font-medium">Budget</p>
                    <p className="text-base-content/70">{project.budget}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Description */}
          <div className="card bg-base-200 lg:col-span-2">
            <div className="card-body">
              <h2 className="card-title">Description</h2>
              <p className="text-base-content/70">{project.description}</p>
            </div>
          </div>
        </div>
      )}

      {activeTab === 'locations' && (
        <div className="card bg-base-200">
          <div className="card-body">
            <h2 className="card-title">Project Locations</h2>
            <div className="space-y-4">
              {project.locations.map((location) => (
                <div
                  key={location.id}
                  className="flex items-start justify-between rounded-lg border border-base-300 p-4"
                >
                  <div className="flex items-start gap-3">
                    <MapPinIcon className="mt-1 h-5 w-5" />
                    <div>
                      <p className="font-medium">{location.name}</p>
                      <p className="text-base-content/70">{location.address}</p>
                    </div>
                  </div>
                  <div className={`badge ${STATUS_STYLES[location.status]}`}>
                    {location.status.replace('_', ' ')}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {activeTab === 'team' && (
        <div className="card bg-base-200">
          <div className="card-body">
            <h2 className="card-title">Project Team</h2>
            <div className="space-y-4">
              {project.team.map((member) => (
                <div
                  key={member.id}
                  className="flex items-start justify-between rounded-lg border border-base-300 p-4"
                >
                  <div className="flex items-start gap-3">
                    <UserGroupIcon className="mt-1 h-5 w-5" />
                    <div>
                      <p className="font-medium">{member.name}</p>
                      <p className="text-sm text-base-content/70">{member.role}</p>
                      <p className="text-sm text-base-content/70">{member.email}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {activeTab === 'documents' && (
        <div className="card bg-base-200">
          <div className="card-body">
            <h2 className="card-title">Project Documents</h2>
            <div className="space-y-4">
              {project.documents.map((doc) => (
                <div
                  key={doc.id}
                  className="flex items-start justify-between rounded-lg border border-base-300 p-4"
                >
                  <div className="flex items-start gap-3">
                    <DocumentIcon className="mt-1 h-5 w-5" />
                    <div>
                      <p className="font-medium">{doc.name}</p>
                      <p className="text-sm text-base-content/70">
                        {doc.type} • Last updated: {doc.lastUpdated}
                      </p>
                    </div>
                  </div>
                  <div className={`badge ${STATUS_STYLES[doc.status]}`}>
                    {doc.status}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
