import React, { useState, useEffect } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';

export default function EditDocumentModal({ isOpen, document, onClose, onSubmit }) {
  const [formData, setFormData] = useState({
    name: '',
    type: '',
    vendor: '',
    status: '',
    expiryDate: '',
    description: '',
    tags: '',
  });

  useEffect(() => {
    if (document) {
      setFormData({
        ...document,
        tags: document.tags.join(', '),
      });
    }
  }, [document]);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({
      ...formData,
      tags: formData.tags.split(',').map((tag) => tag.trim()).filter(Boolean),
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  if (!isOpen) return null;

  return (
    <div className="modal modal-open">
      <div className="modal-box max-w-2xl">
        <button
          onClick={onClose}
          className="btn btn-ghost btn-sm absolute right-2 top-2"
        >
          <XMarkIcon className="h-5 w-5" />
        </button>
        <h3 className="text-lg font-bold">Edit Document</h3>
        <form onSubmit={handleSubmit} className="mt-4 space-y-4">
          <div className="form-control">
            <label className="label">
              <span className="label-text">Document Name</span>
              <span className="label-text-alt text-error">Required</span>
            </label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="input input-bordered"
              placeholder="Enter document name"
              required
            />
          </div>

          <div className="form-control">
            <label className="label">
              <span className="label-text">Document Type</span>
            </label>
            <select
              name="type"
              value={formData.type}
              onChange={handleChange}
              className="select select-bordered"
              required
            >
              <option value="agreement">Agreement</option>
              <option value="contract">Contract</option>
              <option value="legal">Legal</option>
              <option value="invoice">Invoice</option>
            </select>
          </div>

          <div className="form-control">
            <label className="label">
              <span className="label-text">Vendor</span>
            </label>
            <input
              type="text"
              name="vendor"
              value={formData.vendor}
              onChange={handleChange}
              className="input input-bordered"
              placeholder="Enter vendor name"
              required
            />
          </div>

          <div className="form-control">
            <label className="label">
              <span className="label-text">Status</span>
            </label>
            <select
              name="status"
              value={formData.status}
              onChange={handleChange}
              className="select select-bordered"
              required
            >
              <option value="draft">Draft</option>
              <option value="pending_signature">Pending Signature</option>
              <option value="pending_approval">Pending Approval</option>
              <option value="signed">Signed</option>
              <option value="expired">Expired</option>
            </select>
          </div>

          <div className="form-control">
            <label className="label">
              <span className="label-text">Expiry Date</span>
            </label>
            <input
              type="date"
              name="expiryDate"
              value={formData.expiryDate}
              onChange={handleChange}
              className="input input-bordered"
            />
          </div>

          <div className="form-control">
            <label className="label">
              <span className="label-text">Description</span>
            </label>
            <textarea
              name="description"
              value={formData.description}
              onChange={handleChange}
              className="textarea textarea-bordered"
              rows={3}
              placeholder="Enter document description"
            />
          </div>

          <div className="form-control">
            <label className="label">
              <span className="label-text">Tags</span>
              <span className="label-text-alt">Comma separated</span>
            </label>
            <input
              type="text"
              name="tags"
              value={formData.tags}
              onChange={handleChange}
              className="input input-bordered"
              placeholder="legal, contract, confidential"
            />
          </div>

          <div className="modal-action">
            <button type="button" onClick={onClose} className="btn">
              Cancel
            </button>
            <button type="submit" className="btn btn-primary">
              Save Changes
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
