import React from 'react';
import { MoonIcon, SunIcon } from '@heroicons/react/24/outline';

export default function DarkModeToggle({ isDarkMode, onToggle }) {
  return (
    <button
      onClick={onToggle}
      className={`rounded-lg p-2 transition-colors duration-200 ${
        isDarkMode 
          ? 'hover:bg-gray-700 text-yellow-400' 
          : 'hover:bg-gray-100 text-gray-500'
      }`}
      aria-label="Toggle dark mode"
      title={isDarkMode ? 'Switch to light mode' : 'Switch to dark mode'}
    >
      {isDarkMode ? (
        <SunIcon className="h-5 w-5" />
      ) : (
        <MoonIcon className="h-5 w-5" />
      )}
    </button>
  );
}
