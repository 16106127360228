import React, { useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import {
  DocumentIcon,
  CalendarIcon,
  BuildingOfficeIcon,
  TagIcon,
  PencilIcon,
  ArrowDownTrayIcon,
  PencilSquareIcon,
} from '@heroicons/react/24/outline';
import EditDocumentModal from './components/EditDocumentModal';
import SignDocumentModal from './components/SignDocumentModal';

// Mock document data
const MOCK_DOCUMENT = {
  id: 1,
  name: 'Master Service Agreement - Acme Corp',
  type: 'agreement',
  status: 'pending_signature',
  vendor: 'Acme Corp',
  lastUpdated: '2024-01-15',
  expiryDate: '2025-01-15',
  tags: ['legal', 'contract'],
  description: 'Master Service Agreement for software development services.',
  version: '1.0',
  signedBy: null,
  signedDate: null,
  fileSize: '2.5 MB',
  history: [
    {
      id: 1,
      action: 'created',
      user: 'Bob Wilson',
      date: '2024-01-10',
      notes: 'Document created and uploaded',
    },
  ],
};

const STATUS_STYLES = {
  draft: 'badge-warning',
  pending_signature: 'badge-info',
  pending_approval: 'badge-info',
  signed: 'badge-success',
  expired: 'badge-error',
};

export default function DocumentDetailsPage() {
  const { documentId } = useParams();
  const [document, setDocument] = useState(MOCK_DOCUMENT);
  const [activeTab, setActiveTab] = useState('overview');
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isSignModalOpen, setIsSignModalOpen] = useState(false);

  const handleEditDocument = (updatedDocument) => {
    // TODO: Implement document update
    console.log('Updating document:', updatedDocument);
    setDocument(updatedDocument);
    setIsEditModalOpen(false);
  };

  const handleSignDocument = (signatureData) => {
    // TODO: Implement document signing
    console.log('Signing document:', signatureData);
    const updatedDocument = {
      ...document,
      status: 'signed',
      signedBy: signatureData.signedBy,
      signedDate: signatureData.date,
      history: [
        {
          id: document.history.length + 1,
          action: 'signed',
          user: signatureData.signedBy,
          date: signatureData.date,
          notes: `Document signed by ${signatureData.signedBy} (${signatureData.title})`,
        },
        ...document.history,
      ],
    };
    setDocument(updatedDocument);
    setIsSignModalOpen(false);
  };

  const canSign = document.status === 'pending_signature';
  const canEdit = document.status !== 'signed' && document.status !== 'expired';

  return (
    <div className="space-y-6">
      {/* Header */}
      <div className="flex items-start justify-between">
        <div>
          <div className="flex items-center gap-2">
            <h1 className="text-2xl font-semibold text-base-content">
              {document.name}
            </h1>
            <div className={`badge ${STATUS_STYLES[document.status]}`}>
              {document.status.replace('_', ' ')}
            </div>
          </div>
          <div className="mt-1 flex items-center gap-2 text-base-content/70">
            <DocumentIcon className="h-4 w-4" />
            <span>Document ID: {documentId}</span>
          </div>
        </div>
        <div className="flex gap-2">
          <button className="btn btn-outline btn-sm">
            <ArrowDownTrayIcon className="h-4 w-4" />
            Download
          </button>
          {canEdit && (
            <button 
              className="btn btn-outline btn-sm"
              onClick={() => setIsEditModalOpen(true)}
            >
              <PencilIcon className="h-4 w-4" />
              Edit
            </button>
          )}
          {canSign && (
            <button
              className="btn btn-primary btn-sm"
              onClick={() => setIsSignModalOpen(true)}
            >
              <PencilSquareIcon className="h-4 w-4" />
              Sign Document
            </button>
          )}
        </div>
      </div>

      {/* Tabs */}
      <div className="tabs-boxed tabs">
        <button
          className={`tab ${activeTab === 'overview' ? 'tab-active' : ''}`}
          onClick={() => setActiveTab('overview')}
        >
          Overview
        </button>
        <button
          className={`tab ${activeTab === 'history' ? 'tab-active' : ''}`}
          onClick={() => setActiveTab('history')}
        >
          History
        </button>
      </div>

      {/* Content */}
      {activeTab === 'overview' ? (
        <div className="grid gap-6 lg:grid-cols-3">
          {/* Document Details */}
          <div className="card bg-base-200">
            <div className="card-body">
              <h2 className="card-title">Document Details</h2>
              <div className="space-y-4">
                <div className="flex items-start gap-2">
                  <DocumentIcon className="mt-1 h-5 w-5 flex-shrink-0" />
                  <div>
                    <p className="font-medium">Type</p>
                    <p className="text-base-content/70">{document.type}</p>
                  </div>
                </div>
                <div className="flex items-start gap-2">
                  <BuildingOfficeIcon className="mt-1 h-5 w-5 flex-shrink-0" />
                  <div>
                    <p className="font-medium">Vendor</p>
                    <Link
                      to={`/vendors/${1}`}
                      className="text-base-content/70 hover:text-primary"
                    >
                      {document.vendor}
                    </Link>
                  </div>
                </div>
                <div className="flex items-start gap-2">
                  <CalendarIcon className="mt-1 h-5 w-5 flex-shrink-0" />
                  <div>
                    <p className="font-medium">Expiry Date</p>
                    <p className="text-base-content/70">
                      {document.expiryDate || 'No expiry date'}
                    </p>
                  </div>
                </div>
                <div className="flex items-start gap-2">
                  <TagIcon className="mt-1 h-5 w-5 flex-shrink-0" />
                  <div>
                    <p className="font-medium">Tags</p>
                    <div className="flex flex-wrap gap-1">
                      {document.tags.map((tag) => (
                        <span key={tag} className="badge badge-ghost">
                          {tag}
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Additional Information */}
          <div className="card bg-base-200 lg:col-span-2">
            <div className="card-body">
              <h2 className="card-title">Additional Information</h2>
              <div className="space-y-4">
                <div>
                  <p className="font-medium">Description</p>
                  <p className="text-base-content/70">{document.description}</p>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <p className="font-medium">Version</p>
                    <p className="text-base-content/70">{document.version}</p>
                  </div>
                  <div>
                    <p className="font-medium">File Size</p>
                    <p className="text-base-content/70">{document.fileSize}</p>
                  </div>
                  <div>
                    <p className="font-medium">Signed By</p>
                    <p className="text-base-content/70">{document.signedBy}</p>
                  </div>
                  <div>
                    <p className="font-medium">Signed Date</p>
                    <p className="text-base-content/70">{document.signedDate}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="card bg-base-200">
          <div className="card-body">
            <h2 className="card-title">Document History</h2>
            <div className="space-y-4">
              {document.history.map((event) => (
                <div
                  key={event.id}
                  className="flex items-start gap-4 border-l-2 border-primary pl-4"
                >
                  <div className="flex-1">
                    <p className="font-medium">
                      {event.action.charAt(0).toUpperCase() + event.action.slice(1)}
                    </p>
                    <p className="text-sm text-base-content/70">{event.notes}</p>
                    <div className="mt-1 flex items-center gap-2 text-sm text-base-content/50">
                      <span>{event.user}</span>
                      <span>•</span>
                      <span>{event.date}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {/* Edit Document Modal */}
      <EditDocumentModal
        isOpen={isEditModalOpen}
        document={document}
        onClose={() => setIsEditModalOpen(false)}
        onSubmit={handleEditDocument}
      />

      {/* Sign Document Modal */}
      <SignDocumentModal
        isOpen={isSignModalOpen}
        document={document}
        onClose={() => setIsSignModalOpen(false)}
        onSubmit={handleSignDocument}
      />
    </div>
  );
}
