import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  EllipsisHorizontalIcon,
  FunnelIcon,
  PlusIcon,
  DocumentIcon,
  DocumentArrowUpIcon,
  DocumentCheckIcon,
  DocumentTextIcon,
} from '@heroicons/react/24/outline';
import AddDocumentModal from './components/AddDocumentModal';
import { documentAPI } from '../../services/api';

const TYPE_ICONS = {
  agreement: DocumentCheckIcon,
  contract: DocumentTextIcon,
  legal: DocumentIcon,
  invoice: DocumentArrowUpIcon,
};

const STATUS_STYLES = {
  draft: 'badge-warning',
  pending_signature: 'badge-info',
  pending_approval: 'badge-info',
  signed: 'badge-success',
  expired: 'badge-error',
};

export default function DocumentsPage() {
  const navigate = useNavigate();
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filters, setFilters] = useState({
    type: '',
    status: '',
  });

  // Fetch documents on component mount and when filters change
  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        setLoading(true);
        const docs = await documentAPI.getDocuments(filters);
        setDocuments(docs);
        setError(null);
      } catch (err) {
        console.error('Error fetching documents:', err);
        setError('Failed to load documents. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchDocuments();
  }, [filters]);

  const handleAddDocument = async (documentData) => {
    try {
      // TODO: Replace with actual user ID from auth context
      const userId = 'test-user-id';
      const newDocument = await documentAPI.createDocument({
        ...documentData,
        userId,
      });
      setDocuments((prevDocs) => [newDocument, ...prevDocs]);
      setIsAddModalOpen(false);
    } catch (err) {
      console.error('Error creating document:', err);
      // TODO: Show error toast/notification
    }
  };

  const handleDocumentClick = (documentId) => {
    navigate(`/documents/${documentId}`);
  };

  const handleFilterChange = (key, value) => {
    setFilters((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  if (error) {
    return (
      <div className="flex h-full items-center justify-center">
        <div className="text-center">
          <h3 className="text-lg font-medium">Error</h3>
          <p className="mt-1 text-base-content/70">{error}</p>
          <button
            className="btn btn-primary mt-4"
            onClick={() => window.location.reload()}
          >
            Try Again
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {/* Header */}
      <div className="flex items-center justify-between">
        <h1 className="text-2xl font-semibold">Documents</h1>
        <button
          onClick={() => setIsAddModalOpen(true)}
          className="btn btn-primary"
        >
          <PlusIcon className="h-5 w-5" />
          Add Document
        </button>
      </div>

      {/* Filters */}
      <div className="flex items-center gap-4">
        <div className="flex items-center gap-2">
          <FunnelIcon className="h-5 w-5" />
          <span className="font-medium">Filters:</span>
        </div>
        <select
          className="select select-bordered select-sm"
          value={filters.type}
          onChange={(e) => handleFilterChange('type', e.target.value)}
        >
          <option value="">All Types</option>
          <option value="agreement">Agreements</option>
          <option value="invoice">Invoices</option>
          <option value="proposal">Proposals</option>
          <option value="contract">Contracts</option>
          <option value="nda">NDAs</option>
          <option value="legal">Legal</option>
        </select>
        <select
          className="select select-bordered select-sm"
          value={filters.status}
          onChange={(e) => handleFilterChange('status', e.target.value)}
        >
          <option value="">All Status</option>
          <option value="draft">Draft</option>
          <option value="pending_signature">Pending Signature</option>
          <option value="signed">Signed</option>
          <option value="expired">Expired</option>
          <option value="pending_approval">Pending Approval</option>
        </select>
      </div>

      {/* Documents Table */}
      <div className="rounded-lg border border-base-content/10">
        <div className="overflow-x-auto">
          <table className="table">
            <thead>
              <tr>
                <th>Document</th>
                <th>Vendor</th>
                <th>Status</th>
                <th>Last Updated</th>
                <th>Expiry Date</th>
                <th>Tags</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan="7" className="text-center">
                    <span className="loading loading-spinner loading-md"></span>
                  </td>
                </tr>
              ) : documents.length === 0 ? (
                <tr>
                  <td colSpan="7" className="text-center">
                    <p className="py-4 text-base-content/70">No documents found</p>
                  </td>
                </tr>
              ) : (
                documents.map((doc) => (
                  <tr
                    key={doc.id}
                    className="cursor-pointer hover:bg-base-200"
                    onClick={() => handleDocumentClick(doc.id)}
                  >
                    <td className="min-w-[300px]">
                      <div className="flex items-center gap-3">
                        <TypeIcon className="h-5 w-5" />
                        <div>
                          <div className="font-medium">{doc.name}</div>
                          <div className="text-sm opacity-50">{doc.type}</div>
                        </div>
                      </div>
                    </td>
                    <td>{doc.vendor?.name || '-'}</td>
                    <td>
                      <div className={`badge ${STATUS_STYLES[doc.status]}`}>
                        {doc.status.replace('_', ' ')}
                      </div>
                    </td>
                    <td>
                      {new Date(doc.updatedAt).toLocaleDateString()}
                    </td>
                    <td>{doc.expiryDate || '-'}</td>
                    <td>
                      <div className="flex gap-1">
                        {doc.tags.map((tag) => (
                          <span key={tag} className="badge badge-ghost">
                            {tag}
                          </span>
                        ))}
                      </div>
                    </td>
                    <td>
                      <button className="btn btn-ghost btn-sm">
                        <EllipsisHorizontalIcon className="h-5 w-5" />
                      </button>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>

      {/* Add Document Modal */}
      <AddDocumentModal
        isOpen={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
        onSubmit={handleAddDocument}
      />
    </div>
  );
}
