import axios from 'axios';

const api = axios.create({
  baseURL: '',
  headers: {
    'Content-Type': 'application/json',
  },
});

export const documentAPI = {
  // Get all documents with optional filters
  async getDocuments(filters = {}) {
    const { data } = await api.get('/documents', { params: filters });
    return data;
  },

  // Get a single document by ID
  async getDocument(id) {
    const { data } = await api.get(`/documents/${id}`);
    return data;
  },

  // Create a new document
  async createDocument(documentData) {
    const { data } = await api.post('/documents', documentData);
    return data;
  },

  // Update a document
  async updateDocument(id, documentData) {
    const { data } = await api.put(`/documents/${id}`, documentData);
    return data;
  },

  // Sign a document
  async signDocument(id, signatureData) {
    const { data } = await api.post(`/documents/${id}/sign`, signatureData);
    return data;
  },

  // Delete a document
  async deleteDocument(id) {
    await api.delete(`/documents/${id}`);
  },
};

export const userAPI = {
  // Get current user
  async getCurrentUser() {
    const { data } = await api.get('/users/current');
    return data;
  },
};

export const vendorAPI = {
  // Get all vendors with optional filters
  async getVendors(filters = {}) {
    const { data } = await api.get('/vendors', { params: filters });
    return data;
  },

  // Get a single vendor by ID
  async getVendor(id) {
    const { data } = await api.get(`/vendors/${id}`);
    return data;
  },

  // Create a new vendor
  async createVendor(vendorData) {
    const { data } = await api.post('/vendors', vendorData);
    return data;
  },

  // Update a vendor
  async updateVendor(id, vendorData) {
    const { data } = await api.put(`/vendors/${id}`, vendorData);
    return data;
  },

  // Delete a vendor
  async deleteVendor(id) {
    await api.delete(`/vendors/${id}`);
  },
};

export const projectAPI = {
  // Get all projects for a vendor
  async getProjects(vendorId, filters = {}) {
    const { data } = await api.get(`/vendors/${vendorId}/projects`, { params: filters });
    return data;
  },

  // Get a single project by ID
  async getProject(vendorId, projectId) {
    const { data } = await api.get(`/vendors/${vendorId}/projects/${projectId}`);
    return data;
  },

  // Create a new project
  async createProject(vendorId, projectData) {
    const { data } = await api.post(`/vendors/${vendorId}/projects`, projectData);
    return data;
  },

  // Update a project
  async updateProject(vendorId, projectId, projectData) {
    const { data } = await api.put(`/vendors/${vendorId}/projects/${projectId}`, projectData);
    return data;
  },

  // Delete a project
  async deleteProject(vendorId, projectId) {
    await api.delete(`/vendors/${vendorId}/projects/${projectId}`);
  },
};
